<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Transaction Order ({{ this.next + 1 }}/3) </CCardHeader>
					<CCardBody>
						<patient
							:patient="patient"
							v-if="next == 0"
							@setPatientP="setPatient"
						/>

						<products
							:product="products"
							v-if="next == 1"
							@setProductP="setProduct"
						/>

						<confirm v-if="next == 2" :patient="patient" :products="products" />
						<finish v-if="next == 3" :data="dataInsert" />
						<CRow>
							<CCol>
								<CButton
									v-show="this.next > 0"
									@click="nextto('b')"
									color="secondary"
									block
								>
									BACK
								</CButton>
							</CCol>
							<CCol>
								<CButton
									v-if="this.next < 2"
									@click="nextto('n')"
									color="primary"
									block
								>
									NEXT ({{ this.next + 1 }}/3)
								</CButton>
								<CButton
									v-else-if="this.next == 2"
									@click="order"
									color="success"
									block
								>
									ORDER
								</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import Patient from "./orderstep/Patient";
import Products from "./orderstep/Products";
import Confirm from "./orderstep/Confirm";
import Finish from "./orderstep/Finish";
export default {
	components: {
		Patient,
		Products,
		Confirm,
		Finish,
	},
	data() {
		return {
			patient: null,
			products: null,
			next: 0,
			dataInsert: {},
		};
	},
	methods: {
		nextto(to) {
			if (to == "n") {
				this.next >= 3 ? (this.next = 3) : this.next++;
			} else {
				if (this.next == 3) {
					this.next = 0;
				} else this.next <= 0 ? (this.next = 0) : this.next--;
			}
		},
		setPatient(val) {
			this.patient = val;
		},
		setProduct(val) {
			this.products = val;
		},
		async order() {
			try {
				const pat = this.patient;
				const pro = this.products;
				let details = {};
				for (const key in pro.products) {
					const el = pro.products[key];
					details[el.id] = el.amount;
				}
				const frm = {
					patientId: pat.id,
					recipient: pat.name,
					address: pat.address,
					discEventId: pro.discountEvent ? pro.discountEvent.id : null,
					ongkir: pro.ongkir,
					details: details,
				};

				const { data } = await this.$http.post("order", frm);

				if (data.status == "success") {
					this.$toast.success("Success ! " + data.message);
					this.dataInsert = data.data;
					this.next++;
					this.patient = null;
					this.products = null;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
		},
	},
};
</script>
