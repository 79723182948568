<template>
	<div>
		<CRow>
			<CCol md="12">
				<CInput
					label="Patient"
					@keydown="getPatients($event)"
					placeholder="patient name / code"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol md="12">
				<div v-if="patientSelected == null">
					<ellipsis-loader v-show="loading" :color="'#54f1d2'" />
					<table class="table" v-show="!loading">
						<tr>
							<th>Consumer</th>
							<th>Name</th>
							<th>Number</th>
							<th></th>
						</tr>
						<tr v-for="(pat, i) in patients" :key="pat.id">
							<td>{{ pat.consumer.name }}</td>
							<td>{{ pat.name }}</td>
							<td>{{ pat.number }}</td>
							<td>
								<CButton @click="select(i)" color="primary" shape="pill">
									SELECT
								</CButton>
							</td>
						</tr>
					</table>
				</div>
				<div v-else>
					Patient :
					<span class="h5">
						{{ patientSelected.name }} - {{ patientSelected.number }}
					</span>
					<br />
					Consumer :
					<span class="h5">
						{{ patientSelected.consumer.name }} -
						{{ patientSelected.consumer.number }} -
						{{ patientSelected.consumer.points }}
					</span>
					<CInput
						label="Address"
						v-model="patientSelected.address"
						placeholder="address"
					/>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";

export default {
	props: ["patient"],
	data() {
		return {
			patientSelected: null,
			patients: [],
			myPatient: "",
			loading: false,
			address: "",
		};
	},
	mounted() {
		this.patientSelected = this.patient;
	},
	methods: {
		getPatients: debounce(async function(val) {
			this.patientSelected = null;
			this.loading = true;
			const { data } = await this.$http.get("patient", {
				params: { page: 1, per_page: 5, filter: val.target.value },
			});
			this.patients = data.data.data;
			this.loading = false;
		}, 1000),
		select(i) {
			this.patientSelected = this.patients[i];
			this.$emit("setPatientP", this.patientSelected);
		},
	},
};
</script>
