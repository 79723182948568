<template>
	<div>
		<CRow>
			<CCol md="12">
				To :
				<span class="h5">
					{{ myPatient ? myPatient.name + " - " + myPatient.number : "-" }}
				</span>
				<br />
				Address :
				<span class="h5">
					{{ myPatient ? myPatient.address : "-" }}
				</span>
				<br /><br />
			</CCol>
			<CCol md="12">
				<table class="table">
					<tr class="text-center">
						<th>Name</th>
						<th>Price</th>
						<th>Amount</th>
						<th>Total</th>
						<th>Disc.(%)</th>
						<th>Total</th>
					</tr>
					<tr
						v-for="(prod, i) in myProducts ? myProducts.products : {}"
						:key="i"
					>
						<td>{{ prod.name }}</td>
						<td class="text-right">{{ prod.price.toLocaleString() }}</td>
						<td class="text-center">{{ prod.amount.toLocaleString() }}</td>
						<td class="text-right">{{ prod.total.toLocaleString() }}</td>
						<td class="text-center">{{ prod.discount }}</td>
						<td class="text-right">
							{{ prod.totalDiscount.toLocaleString() }}
						</td>
					</tr>
					<tr>
						<td class="text-right" colspan="5"></td>
						<td class="text-right">{{ totalHarga.toLocaleString() }}</td>
					</tr>
					<tr>
						<td class="text-right" colspan="4">
							{{ myProducts ? myProducts.discountEvent.name : "" }}
						</td>
						<td class="text-center" colspan="1">
							{{ discountEvent * 100 }}
						</td>
						<td class="text-right">
							({{ (totalHarga * discountEvent).toLocaleString() }})
						</td>
					</tr>
					<tr>
						<td class="text-right" colspan="4">Discount Point</td>
						<td class="text-center">-</td>
						<td class="text-right">0</td>
					</tr>
					<tr>
						<td class="text-right" colspan="4">
							Ongkir
						</td>
						<td></td>
						<td class="text-right">
							{{ myProducts ? myProducts.ongkir.toLocaleString() : 0 }}
						</td>
					</tr>
					<tr>
						<td class="text-right" colspan="5">
							<span class="h5">GRAND TOTAL</span>
						</td>
						<td class="text-right">
							<span class="h5">{{ totalGrand.toLocaleString() }}</span>
						</td>
					</tr>
				</table>
			</CCol>
		</CRow>
	</div>
</template>

<script>
export default {
	props: ["patient", "products"],

	computed: {
		myProducts: function() {
			return this.products ? this.products : null;
		},
		myPatient: function() {
			return this.patient ? this.patient : null;
		},
		totalHarga: function() {
			let tot = 0;
			if (this.myProducts) {
				for (const key in this.myProducts.products) {
					if (Object.hasOwnProperty.call(this.products.products, key)) {
						const el = this.myProducts.products[key];
						tot += el.totalDiscount;
					}
				}
			}
			return tot;
		},
		totalGrand: function() {
			let tot = 0;
			let discEvent = 0;

			if (this.myProducts) {
				discEvent =
					(this.totalHarga * this.myProducts.discountEvent.discount) / 100;
			}

			if (this.myProducts) {
				tot = this.totalHarga + this.myProducts.ongkir - discEvent;
			}

			return tot;
		},
		discountEvent: function() {
			return this.myProducts ? this.myProducts.discountEvent.discount / 100 : 0;
		},
	},
};
</script>
