<template>
	<div>
		<CRow>
			<CCol md="6">
				<div role="group" class="form-group">
					<label for="province" class=""> Products </label>
					<select v-model="iproductSelected" id="province" class="form-control">
						<option
							v-for="(pro, i) in products"
							:key="i"
							:data-key="pro.id"
							:value="i"
						>
							{{ pro.name }}
						</option>
					</select>
				</div>
			</CCol>
			<CCol md="3">
				<CInput
					v-model.number="amount"
					label="Amount"
					placeholder=""
					value="0"
					type="number"
				/>
			</CCol>
			<CCol md="3">
				<CButton style="margin-top:27px;" @click="add" color="success" block>
					ADD
				</CButton>
			</CCol>
		</CRow>
		<CRow>
			<table class="table">
				<tr class="text-center">
					<th>Name</th>
					<th>Price</th>
					<th>Amount</th>
					<th>Total</th>
					<th>Disc.(%)</th>
					<th>Total</th>
					<th></th>
				</tr>
				<tr v-for="(prod, i) in productsSelected" :key="i">
					<td>{{ prod.name }}</td>
					<td class="text-right">{{ prod.price.toLocaleString() }}</td>
					<td class="text-center">{{ prod.amount.toLocaleString() }}</td>
					<td class="text-right">{{ prod.total.toLocaleString() }}</td>
					<td class="text-center">
						{{ prod.discount }}<br />
						({{ ((prod.total * prod.discount) / 100).toLocaleString() }})
					</td>
					<td class="text-right">{{ prod.totalDiscount.toLocaleString() }}</td>
					<td>
						<CButton @click="remove(i)" color="danger" shape="pill">
							DELETE
						</CButton>
					</td>
				</tr>
				<tr>
					<td class="text-right" colspan="5"></td>
					<td class="text-right">{{ totalHarga.toLocaleString() }}</td>
					<td></td>
				</tr>
				<tr>
					<td class="text-right" colspan="4">
						<select
							@change="setPromoSelected($event)"
							id="promos"
							class="form-control"
						>
							<option value="">No discount event</option>
							<option
								v-for="(pro, i) in promos"
								:key="i"
								:data-key="pro.id"
								:value="i"
							>
								{{ pro.name }}
							</option>
						</select>
					</td>
					<td class="text-center" colspan="1">
						{{ promoSelected ? promoSelected.discount : 0 }}<br />
						({{
							((totalHarga * promoSelected.discount) / 100).toLocaleString()
						}})
					</td>
					<td class="text-right">
						{{
							(
								totalHarga -
								(totalHarga * promoSelected.discount) / 100
							).toLocaleString()
						}}
					</td>
					<td></td>
				</tr>
				<tr>
					<td class="text-right" colspan="4">Discount Point</td>
					<td class="text-center">-</td>
					<td class="text-right">0</td>
					<td></td>
				</tr>
				<tr>
					<td class="text-right" colspan="4">
						Ongkir
						<input v-model.number="ongkir" type="number" min="0" />
					</td>
					<td></td>
					<td class="text-right">{{ ongkir.toLocaleString() }}</td>
					<td></td>
				</tr>
				<tr>
					<td class="text-right" colspan="5">
						<span class="h5">GRAND TOTAL</span>
					</td>
					<td class="text-right">
						<span class="h5">{{ totalGrand.toLocaleString() }}</span>
					</td>
					<td></td>
				</tr>
			</table>
		</CRow>
	</div>
</template>

<script>
export default {
	props: ["product"],
	data() {
		return {
			products: [],
			productsSelected: {},
			iproductSelected: null,
			amount: 0,
			promos: [],
			promoSelected: { discount: 0 },
			ongkir: 0,
		};
	},
	computed: {
		totalHarga: function() {
			let tot = 0;
			for (const key in this.productsSelected) {
				if (Object.hasOwnProperty.call(this.productsSelected, key)) {
					const el = this.productsSelected[key];
					tot += el.totalDiscount;
				}
			}
			return tot;
		},
		totalGrand: function() {
			let tot = 0;
			let discEvent = (this.totalHarga * this.promoSelected.discount) / 100;
			tot = this.totalHarga + this.ongkir - discEvent;

			this.$emit("setProductP", {
				products: this.productsSelected,
				ongkir: this.ongkir,
				discountEvent: this.promoSelected,
				discountPoin: {},
			});

			return tot;
		},
	},
	watch: {
		productsSelected: {
			handler(val) {},
			deep: true,
		},
	},
	mounted() {
		this.getProducts();
		this.getDiscountEvent();
		if (this.product) {
			this.productsSelected = this.product.products;
			this.ongkir = this.product.ongkir;
			this.promoSelected = this.product.discountEvent;
		}
	},
	methods: {
		async getProducts() {
			const { data } = await this.$http.get("product", {
				params: { page: 1, per_page: 3000 },
			});
			this.products = data.data.data;
		},
		async getDiscountEvent() {
			const now = new Date(Date.now()).toISOString().split("T")[0];
			const { data } = await this.$http.get("promo", {
				params: { page: 1, per_page: 3000, start: now, end: now },
			});
			this.promos = data.data.data;
		},
		remove(key) {
			this.$delete(this.productsSelected, key);
		},
		add() {
			const prod = this.products[this.iproductSelected];
			const total = prod.price * this.amount;
			const totalDiscount = total - (total * prod.discount) / 100;

			if (this.productsSelected[prod.id] !== undefined && this.amount == 0) {
				this.$delete(this.productsSelected, prod.id);
			} else if (
				this.productsSelected[prod.id] === undefined &&
				this.amount == 0
			) {
				return;
			} else {
				this.$set(this.productsSelected, prod.id, {
					amount: this.amount,
					name: prod.name,
					id: prod.id,
					discount: prod.discount,
					price: prod.price,
					total: total,
					totalDiscount: totalDiscount,
				});
			}
		},
		setPromoSelected(e) {
			if (e.target.value) {
				this.promoSelected = this.promos[e.target.value];
			} else {
				this.promoSelected = { discount: 0 };
			}
		},
	},
};
</script>
