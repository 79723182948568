<template>
	<div>
		<CRow>
			<CCol>
				<span class="h4">Your Order is Complete</span>
			</CCol>
		</CRow>
		<CRow class="mb-5">
			<CCol>
				<span class="h4">
					Order Code :
					<span class="text-success">{{ data.code }}</span>
				</span>
			</CCol>
		</CRow>
	</div>
</template>

<script>
export default {
	props: ["data"],
};
</script>
